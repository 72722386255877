import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UsuarioService } from "../../servicio/usuario.service";
import { ListarVentaService } from "../../servicio/listar-venta/listar-venta.service"
import Swal from "sweetalert2"

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  rol: number;
}
export const ROUTES: RouteInfo[] = [
  { path: "/dashboard", title: "Inicio", icon: "dashboard", class: "", rol: 0 },
  { path: "/venta", title: "Venta", icon: "sell", class: "", rol: 0 },
  {
    path: "/listar-venta",
    title: "Listar Ventas",
    icon: "list",
    class: "",
    rol: 0,
  },
  {
    path: "/medicamentos",
    title: "Inventario",
    icon: "content_paste",
    class: "",
    rol: 0,
  },
  {
    path: "/medicamentos/vencidos",
    title: "Vencido",
    icon: "assignment_late",
    class: "",
    rol: 0,
  },
  {
    path: "/reportes",
    title: "Reportes",
    icon: "bar_chart",
    class: "",
    rol: 1,
  },
  {
    path: "/notifications",
    title: "Advertencias",
    icon: "notifications",
    class: "",
    rol: 0,
  },
  {
    path: "/administrar",
    title: "Administrar",
    icon: "settings",
    class: "",
    rol: 0,
  },
  { path: "/maps", title: "Ayuda", icon: "help", class: "", rol: 0 },
  {
    path: "/login",
    title: "Cerrar sesión",
    icon: "logout",
    class: "active-pro",
    rol: 0,
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  public persona;
  public nombre;
  public linkTutorial: string = "";
  constructor(private userService: UsuarioService, private _router: Router, private _listVentaService: ListarVentaService,) {}
  
  public totalNotificaciones = 0  

  ngOnInit() {    
    this.persona = this.userService.getIdentity();
    this.menuItems = ROUTES.filter((menuItem) => {
      if (this.persona.rol >= menuItem.rol ) {
        return menuItem;
      }      
    });
    this.nombre=this.persona.nombre.split(" ");
    this.nombre=this.nombre[0]  
    this.productsLittleStock()
    this.productsToExpire()
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  }

  clickNoti(){
    this.totalNotificaciones = 0
    this.productsLittleStock()
    this.productsToExpire()
  }

  productsLittleStock(){
    const fechaFinal = new Date()
    const fechaInicial = new Date()
    fechaInicial.setDate(fechaInicial.getDate() - 90)    
    this._listVentaService.notiPocaExistencia(fechaInicial, fechaFinal).subscribe(
      response => {
        if(response.pocaexistencia){          
          this.totalNotificaciones += response.pocaexistencia.length
        }
      },
      error => {
        let errorMessage = <any>error;        
        console.error({errorInitInforme: errorMessage})
      }
    )
  }

  productsToExpire() {
    const fechaFinal = new Date()
    const fechaInicial = new Date()
    fechaFinal.setDate(fechaFinal.getDate() + parseInt(localStorage.getItem("value")))    
    this._listVentaService.productosProximosVencer(fechaInicial, fechaFinal).subscribe(
      response => {
        if (response.ventas) {
          this.totalNotificaciones += response.ventas.length
        }
      },
      error => {
        let errorMessage = <any>error;
        console.error({ errorInitInforme: errorMessage })        
      }
    )
  }

  videoTutorial() {
    let rutaActual = this._router.url;
    let rol = this.userService.persona.rol    
    if (rutaActual.includes("/dashboard")) {//---estando en INICIO
      //Lista de reproducción si es ADMIN
      this.linkTutorial = "";
      //Lista de reproducción si es VENDEDOR
      if(rol != 1)
        this.linkTutorial = "";
    }
    else if (rutaActual.includes("/venta")) {//---estando en VENTA
      //Lista de reproducción si es ADMIN
      this.linkTutorial = "https://www.youtube.com/watch?v=7ArBeZBmKGI&list=PLyzIScpJmd1xnmZpdvzJUcrh6CLSlgVtQ&index=2";
      //Lista de reproducción si es VENDEDOR
      if(rol != 1)
        this.linkTutorial = "https://www.youtube.com/watch?v=eTLZDg19cxo&list=PLyzIScpJmd1woVPOCumy8n0wT9DlcUtck&index=2";
    }
    else if (rutaActual.includes("/listar-venta")) {//---estando en LISTAR VENTA
      //Lista de reproducción si es ADMIN
      this.linkTutorial = "https://www.youtube.com/watch?v=fjSA6AVcIhM&list=PLyzIScpJmd1xnmZpdvzJUcrh6CLSlgVtQ&index=1&t=7s";
      //Lista de reproducción si es VENDEDOR
      if(rol != 1)
        this.linkTutorial = "https://www.youtube.com/watch?v=H1DyFIlGmDM&list=PLyzIScpJmd1woVPOCumy8n0wT9DlcUtck&index=1";
    } 
    else if (rutaActual.includes("/medicamentos") || rutaActual.includes("/lotes/conversion")) {//---estando en INVENTARIO
      //Lista de reproducción si es ADMIN
      this.linkTutorial = "https://www.youtube.com/playlist?list=PLyzIScpJmd1wYhM0s5lCdPIPEb3ZnCGu6";
      //Lista de reproducción si es VENDEDOR
      if(rol != 1)
        this.linkTutorial = "https://youtu.be/KWr6C6DjevA";
    } 
    else if (rutaActual.includes("/reportes")) {//---estando en REPORTES
      this.linkTutorial = "https://youtu.be/IlZC2Stlytw";
    } 
    else if (rutaActual.includes("/notifications")) {//---estando en ADVERTENCIAS
      this.linkTutorial = "";    
    } 
    else if (rutaActual.includes("/administrar") || rutaActual.includes("/servicio/listar")) {//---estando en ADMINISTRAR
      //Lista de reproducción si es ADMIN
      this.linkTutorial = "https://www.youtube.com/playlist?list=PLyzIScpJmd1zbJUB-RPyd8WVXgzomZZm9";
      //Lista de reproducción si es VENDEDOR
      if(rol != 1)
        this.linkTutorial = "https://www.youtube.com/playlist?list=PLyzIScpJmd1wjKOAZSxyjU4qTWRUv7ZLB";
    }   
    else {
      this.linkTutorial = "";
    }

    if (this.linkTutorial === "") {
      Swal.fire("¡Lo siento!", "No hay tutorial para este módulo", "info");
    } else window.open(this.linkTutorial, "_blank");
  }

  logOut() {
    localStorage.clear();
    //  this.idle.stop();
    this._router.navigate(["/login"]);
  }
}
