export class Usuario {
    usuario_id:    number;
    nombreUsuario: string;
    password:      string;
    nombre:        string;
    apellido:      string;
    estado:        number;
    rol:           number;
    persona_id:    number;
    createdAt:     string;
    updatedAt:     string;
  }
  