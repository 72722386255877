import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { UsuarioService } from './servicio/usuario.service';
@Injectable({
  providedIn: 'root'
})
export class UserguardService {

  constructor(
    private _router: Router,
    private _userService: UsuarioService
  ) { }



  canActivate() {
    let identity = this._userService.getIdentity();
    if (identity) {
      return true;
    } else {
      this._router.navigate(['/login'])
      return false;
    }

  }
}
