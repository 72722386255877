import { Component, OnInit } from '@angular/core';
import { UsuarioService } from "../servicio/usuario.service";

@Component({
  selector: 'app-administrar',
  templateUrl: './administrar.component.html',
  styleUrls: ['./administrar.component.css']
})
export class AdministrarComponent implements OnInit {
  rol:boolean
  constructor(private userService: UsuarioService) { }

  ngOnInit(): void {
    this.rol= this.userService.persona.rol
    console.log(this.rol)    
  }

}
