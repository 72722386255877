import { Component, OnInit } from "@angular/core";
import { UsuarioService } from "../../servicio/usuario.service";
import { Usuario } from "app/models/usuario.model";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { MatTableDataSource } from "@angular/material/table";
import Swal from "sweetalert2";
import { MatDialog } from "@angular/material/dialog";
import { AgregarUsuarioComponent } from "./agregar-usuario/agregar-usuario.component";

@Component({
  selector: "app-usuarios",
  templateUrl: "./usuarios.component.html",
  styleUrls: ["./usuarios.component.css"],
})
export class UsuariosComponent implements OnInit {
  dataSourceTemp: any[];
  cargando: boolean = true;
  changingPass: boolean = false;
  _usuarioFromList: Usuario = new Usuario();
  private dataSource: any = new MatTableDataSource();

  displayedColumns: string[] = [
    "usuario_id",
    "nombreUsuario",
    "nombre",
    "apellido",
    "rol",
    "estado",
    "opciones",
  ];

  constructor(
    private userService: UsuarioService,
    private _router: Router,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let currentUser = this.userService.getIdentity().rol
    if(currentUser != 1){
      this._router.navigate(['/administrar'])
    }
    this.userService.listarUsuarios().subscribe(
      (response) => {
        this.cargando = false;
        this.dataSource = new MatTableDataSource(response.usuario);
      },
      (error) => {
        if (error.status == 422) {
          Swal.fire("Lo siento", "Tu sesión ha exirado", "info");
          localStorage.clear();
          this._router.navigate(["/login"]);
        }
        this.cargando = false;
      }
    );
  }

  openDialog(actualizar: number) {
    if (actualizar < 1) {
      // si es menor de 1 es porque esta agregando
      const dialogRef = this.dialog.open(AgregarUsuarioComponent, {
        data: {
          nombreUsuario: "",
          nombre: "",
          apellido: "",
          rol: 0,
          estado: 1,
          updating: false,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res === undefined) {
          console.log("No recibi nada para crear");
        } else if (res == "" || res == null) {
          Swal.fire(
            "No ocurrido un error",
            "No se puede dejar un el nombre vacio",
            "warning"
          );
        } else {
          console.log("Respuesta del dialog", res);
          this._usuarioFromList = res;
          //guardar tiene la llamda al servicio para agregar al usuario
          this.guardar();
        }
      });
    } else {
      this.dataSource.data.map((x) => {
        if (x.usuario_id === actualizar) {
          this._usuarioFromList = x;
        }
      });
      const dialogRef = this.dialog.open(AgregarUsuarioComponent, {
        data: {
          usuario_id: this._usuarioFromList.usuario_id,
          nombreUsuario: this._usuarioFromList.nombreUsuario,
          nombre: this._usuarioFromList.nombre,
          apellido: this._usuarioFromList.apellido,
          rol: this._usuarioFromList.rol,
          estado: this._usuarioFromList.estado,
          updating: true,
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res === undefined) {
          console.log("No recibi nada para actualziar");
        } else {
          console.log(res);
          if (res[1]) {
            //en res[1] se tiene el booleano que indica si se actualizará la contraseña, si es true si se cambia
            this._usuarioFromList = res[0];
            console.log("Usuario de la lista", this._usuarioFromList);
            this.actualizarConPassword();
          } else {
            this._usuarioFromList = res[0];
            console.log("Usuario de la lista sin contraseña", this._usuarioFromList);
            this.actualizarSinPassword();
            //es decir no actualiza la contraseña
          }
        }
      });
    }
  }

  guardar() {
    this.userService.guardarUsuario(this._usuarioFromList).subscribe(
      (response) => {
        if (response == null) {
          Swal.fire("Lo siento", "No se pudo guardar", "error");
        } else {
          this.ngOnInit();
          Swal.fire(
            "Excelente!",
            "Usuario: " +
              this._usuarioFromList.nombreUsuario +
              " Agregado correctamente",
            "success"
          );
        }
      },
      (error) => {
        var errorMessage = <any>error;
        console.log(errorMessage);
        if (error.status == 422) {
          Swal.fire("Lo siento", "Tu sesión ha exirado", "info");
          localStorage.clear();
          this._router.navigate(["/login"]);
        }
        this.cargando = false;
        if (errorMessage != null) {
          Swal.fire("Lo siento", "No se pudo guardar", "error");
        }
      }
    );
  }

  actualizarConPassword() {
    this.userService.actualizarUsuarioYPassword(this._usuarioFromList).subscribe(
      (response) => {
        if (response == null) {
          console.log("Error al guardar");
          Swal.fire("Lo siento", "No se pudo guardar", "error");
        } else {
          this.ngOnInit();
          Swal.fire("Bien!", "Actualizado correctamente", "success");
          //this._religion = response.
        }
      },
      (error) => {
        if (error.status == 422) {
          Swal.fire("Lo siento", "Tu sesión ha exirado", "info");
          localStorage.clear();
          this._router.navigate(["/login"]);
        }
        this.cargando = false;
        var errorMessage = <any>error;
        console.log(errorMessage);
        if (errorMessage != null) {
          Swal.fire("Lo siento", "No se pudo guardar", "error");
        }
      }
    );
  }

  actualizarSinPassword() {
    this.userService.actualizarUsuario(this._usuarioFromList).subscribe(
      (response) => {
        if (response == null) {
          console.log("Error al guardar");
          Swal.fire("Lo siento", "No se pudo guardar", "error");
        } else {
          this.ngOnInit();
          Swal.fire("Bien!", "Actualizado correctamente", "success");
          //this._religion = response.
        }
      },
      (error) => {
        if (error.status == 422) {
          Swal.fire("Lo siento", "Tu sesión ha exirado", "info");
          localStorage.clear();
          this._router.navigate(["/login"]);
        }
        this.cargando = false;
        var errorMessage = <any>error;
        console.log(errorMessage);
        if (errorMessage != null) {
          Swal.fire("Lo siento", "No se pudo guardar", "error");
        }
      }
    );
  }
}
