import { Component, Inject, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import Swal from "sweetalert2";

@Component({
  selector: "app-agregar-usuario",
  templateUrl: "./agregar-usuario.component.html",
  styleUrls: ["./agregar-usuario.component.css"],
})
export class AgregarUsuarioComponent implements OnInit {
  updating: boolean;
  public selectedRol: number;
  public selectedEstado: number;
  public changingPass: number;

  _roles = [
    { value: 1, viewValue: "Administrador" },
    { value: 0, viewValue: "Vendedor" },
  ];

  _estados = [
    { value: 1, viewValue: "Activo" },
    { value: 0, viewValue: "Inactivo" },
  ];

  constructor(
    public dialogRef: MatDialogRef<AgregarUsuarioComponent>,
    private _formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  public nombreControl = new FormControl("", [
    Validators.required,
    Validators.pattern(
      "^[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+(\\s*[a-zA-ZÀ-ÿ\\u00f1\\u00d1]*)*[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+$"
    ),
  ]);

  public apellidoControl = new FormControl("", [
    Validators.required,
    Validators.pattern(
      "^[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+(\\s*[a-zA-ZÀ-ÿ\\u00f1\\u00d1]*)*[a-zA-ZÀ-ÿ\\u00f1\\u00d1]+$"
    ),
  ]);

  public nombreUsuarioControl = new FormControl("", [
    Validators.required,
    Validators.pattern("[a-zA-Z]{5,12}"),
  ]);

  public passwordUsuarioControl = new FormControl("", [
    Validators.required,
    Validators.pattern("^(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{8,25}$"),
  ]);

  ngOnInit(): void {
    console.log(this.data);
    this.updating = this.data.updating;
    this.nombreControl.setValue(this.data.nombre);
    this.apellidoControl.setValue(this.data.apellido);
    this.nombreUsuarioControl.setValue(this.data.nombreUsuario);
    this.selectedRol = this.data.rol;
    this.selectedEstado = this.data.estado;
  }

  clickNo(): void {
    this.dialogRef.close();
  }

  Aceptar(): void {
    if (!this.updating) {
      //si no esta acutalizando
      if (
        this.nombreControl.valid &&
        this.apellidoControl.valid &&
        this.nombreUsuarioControl.valid &&
        this.passwordUsuarioControl.valid
      ) {
        console.log("entro");
        this.data.nombre = this.nombreControl.value;
        this.data.apellido = this.apellidoControl.value;
        this.data.nombreUsuario = this.nombreUsuarioControl.value;
        this.data.rol = this.selectedRol;
        this.data.estado = this.selectedEstado;
        this.data.password = this.passwordUsuarioControl.value;
        this.dialogRef.close(this.data);
      } else {
        Swal.fire(
          "Cuidado",
          "Verifica que todos los campos sean validos",
          "error"
        );
      }
    } else {
      if (this.changingPass) {
        if (
          this.nombreControl.valid &&
          this.apellidoControl.valid &&
          this.nombreUsuarioControl.valid &&
          this.passwordUsuarioControl.valid
        ) {
          this.data.nombre = this.nombreControl.value;
          this.data.apellido = this.apellidoControl.value;
          this.data.nombreUsuario = this.nombreUsuarioControl.value;
          this.data.rol = this.selectedRol;
          this.data.estado = this.selectedEstado;
          this.data.password = this.passwordUsuarioControl.value;
          this.dialogRef.close([this.data, this.changingPass]);
        } else {
          Swal.fire(
            "Cuidado",
            "Verifica que todos los campos sean validos",
            "error"
          );
        }
      } else {
        if (
          this.nombreControl.valid &&
          this.apellidoControl.valid &&
          this.nombreUsuarioControl.valid
        ) {
          this.data.nombre = this.nombreControl.value;
          this.data.apellido = this.apellidoControl.value;
          this.data.nombreUsuario = this.nombreUsuarioControl.value;
          this.data.rol = this.selectedRol;
          this.data.estado = this.selectedEstado;          
          this.dialogRef.close([this.data, this.changingPass]);
        } else {
          Swal.fire(
            "Cuidado",
            "Verifica que todos los campos sean validos",
            "error"
          );
        }
      }
    }
  }
}
