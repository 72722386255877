import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GLOBAL } from "./global";
import { Observable } from "rxjs";
import { Usuario } from "app/models/usuario.model";

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  public url: string;
  public token;
  public headers;
  public persona;
  public _usuarioService: UsuarioService;

  userSesion;  

  setUserSesion(val: boolean){
    this.userSesion = val;
  }

  getUserSesion(){
    return this.userSesion;
  }

  constructor(public _http: HttpClient) {
    this.userSesion = false;
    this.url = GLOBAL.url;    
  }

  singup(usuario: Usuario): Observable<any> {    
    let params = JSON.stringify(usuario);
    this.headers = new HttpHeaders().set("Content-Type", "application/json");
    let  a = this._http.post(this.url + "usuario/login", params, {
      headers: this.headers,
    });    
    return a;
  }
  getToken() {
    let token = localStorage.getItem("user-token");

    if (token != "undefined") {
      this.token = token;
    } else {
      this.token = null;
    }
    let userToken = {'user-token': this.token}    
    return userToken
  }
  getUserToken(){
    let token = localStorage.getItem("user-token");
    if (token != "undefined") {
      this.token = token;
    } else {
      this.token = null;
    }    
    return this.token
  }
  
  getPersonas(): Observable<any> {    
    return this._http.get(this.url + "persona/",{ headers: this.setTokenHeader() });
  }

  getIdentity(){
    let persona = JSON.parse(localStorage.getItem('persona'));    
    if (persona != "undefined") {
        this.persona = persona;
    } else {
        this.persona = null;
    }
    return this.persona;
  }

  setTokenHeader(){
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("user-token", this.getUserToken());
      return headers
  }

  listarUsuarios(): Observable<any> {
    let a = this._http.get(this.url + "usuario", {headers: this.setTokenHeader()});    
    return a;
  }
  
  actualizarUsuarioYPassword(_usuario: Usuario): Observable<any> {    
    let body = (_usuario);        
    let a = this._http.put(this.url + "usuario/actualizar-password", body, {headers: this.setTokenHeader()});    
    return a;                
  }

  actualizarUsuario(_usuario: Usuario): Observable<any> {//se actualizan sus datos más no su contraseña
    let body = (_usuario);        
    let a = this._http.put(this.url + "usuario/actualizar-datos", body, {headers: this.setTokenHeader()});    
    return a;                
  }
  
  guardarUsuario(_usuario: Usuario): Observable<any> {
    let body = (_usuario);
    let a = this._http.post(this.url + "usuario", body, {headers: this.setTokenHeader()});
    return a;
  }  
  getDaysToExpireMedication(token): Observable<any> {
    let headers = new HttpHeaders()
      .set("Content-Type", "application/json")
      .set("user-token", token);

    let a = this._http.get(this.url + "generic/1", { headers: headers });
    return a;
  }
  setDaysToExpireMedication(numberOfDaysToExpired): Observable<any> {
    let header = this.getToken();
    let body = ({numberOfDaysToExpired});        
    let a = this._http.put(this.url + "generic/1", body, {headers: header});    
    return a;      
  }
}
