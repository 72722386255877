import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { UsuarioService } from "../servicio/usuario.service";
import { MedicamentoService } from "../servicio/medicamento/medicamento.service";
import { Usuario } from "app/models/usuario.model";
import { Router, ActivatedRoute, Params } from "@angular/router";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  cargando: boolean;
  public status: string;
  public firstFormGroup: FormGroup;
  public identity;
  public token;
  usuario: Usuario;
  //form controls para login
  usernameFormControl = new FormControl("", [
    Validators.required,
    Validators.pattern("[a-zA-Z]{5,12}"),
  ]);
  passwordFormControl = new FormControl("", [
    Validators.required,
    Validators.pattern("^(?=\\w*\\d)(?=\\w*[A-Z])(?=\\w*[a-z])\\S{8,16}$"),
  ]);
  constructor(
    private _formBuilder: FormBuilder,
    private userService: UsuarioService,
    private medicamentoService: MedicamentoService,
    private _router: Router,
  ) {
    this.usuario = new Usuario();
  }

  ngOnInit(): void {
    this.firstFormGroup = this._formBuilder.group({
      nombreUsuarioControl: this.usernameFormControl,
      passwordControl: this.passwordFormControl,
    });
  }

  Ingresar() {
    this.cargando = true;
    this.usuario.nombreUsuario = this.usernameFormControl.value;
    this.usuario.password = this.passwordFormControl.value;
    //console.log(this.usuario)
    this.userService.singup(this.usuario).subscribe(
      (response) => {
        this.token = response.success;
        this.identity = response.persona;
        if (this.token.length <= 0) {
          this.status = "error";
          this._router.navigate(["/login"]);
          this.cargando = false;
        } else {
          //
          this.status = "success";
          localStorage.setItem("user-token", this.token);
          localStorage.setItem("persona", JSON.stringify(this.identity));
          this.userService.setUserSesion(true);
          let persona = this.userService.getIdentity()
          //actualizando los medicamentos que estan vencidos
          this.medicamentoService.actualizarEstadoVencido().subscribe(response => {
            console.log(response)
          })
          this.userService.getDaysToExpireMedication(this.token).subscribe(response => {
            localStorage.setItem("value", response.generic.numberOfDaysToExpired)
            if (persona.rol > 0) {
              this._router.navigate(["/dashboard"]);
            } else {
              this._router.navigate(["/venta"]);
            }
          })
          //
        }
      },
      (error) => {
        var errorMessage = <any>error;
        console.log(errorMessage);
        if (errorMessage != null) {
          this.status = "error";
          this.cargando = false;
        }
      }
    );

  }
}
