import { Component} from '@angular/core';
import { UsuarioService } from './servicio/usuario.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})


export class AppComponent {
  constructor(private userService: UsuarioService ) { }

  changeGV(val){
    this.userService.setUserSesion(val);
  }

  showGV(){
    alert("GV: " + this.userService.getUserSesion());
  }
}
