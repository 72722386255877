import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
//Componentes Xela AID
import { GLOBAL } from "../global";
import { Medicamento } from 'app/models/medicamento.model';
import { Venta } from "app/models/venta.model";
import { UsuarioService } from '../usuario.service'
import Swal from 'sweetalert2';
import { catchError } from 'rxjs/operators'
@Injectable({
  providedIn: 'root'
})
export class MedicamentoService {
  public url: string;
  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService
  ) {
    this.url = GLOBAL.url;
  }

  capturarError(error: HttpErrorResponse) {
    if (error.status === 422) {
      Swal.fire({
        icon: 'info',
        title: '¡Lo siento!',
        text: 'Su sesión ha expirado',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000,
        willClose: () => {
          localStorage.clear()
          window.location.replace("/login")
          //window.location.reload();  
        },
      })
    }
    return throwError(error)
  }

  listarMedicamentos(currentPaginator: number, elementsPerPage: number, loadExpired:number = 0): Observable<any> {
    let header = this._usuarioService.getToken();
    const body = { currentPaginator, elementsPerPage, loadExpired }
    let a = this._http.post(this.url + `medicamento/listar/`, body, {
      headers: header
    }).pipe(catchError(this.capturarError));
    return a;
  }


  guardarMedicamento(_medicamento: Medicamento): Observable<any> {
    let body = _medicamento;
    let headers = this._usuarioService.getToken();
    let a = this._http.post(this.url + "medicamento", body, {
      headers: headers,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  buscarMedicamentoInventario(parametro: string, currentPaginator: number, elementsPerPage: number, loadExpired:number = 0): Observable<any> {
    let header = this._usuarioService.getToken();
    let body = {
      parametro,
      currentPaginator,
      elementsPerPage,
      loadExpired
    }
    let a = this._http.post(`${this.url}medicamento/inventario/buscar`, body, { headers: header }).pipe(catchError(this.capturarError));
    return a;
  }

  buscarMedicamento(id: number): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `medicamento/buscar?id=${id}`, { headers: header }).pipe(catchError(this.capturarError));
    return a;
  }

  listarInventario(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "inventariomedicamentos", { headers: header }).pipe(catchError(this.capturarError));
    return a;
  }
  descargarInventario(loadExpired: number = 0): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(`${this.url}inventariomedicamentos/exportar?loadExpired=${loadExpired}`, { headers: header, responseType: 'blob' }).pipe(catchError(this.capturarError));
    return a;
  }

  actualizarMedicamento(_medicamento: Medicamento, id: number): Observable<any> {
    let header = this._usuarioService.getToken();
    let body = _medicamento;
    let a = this._http.put(this.url + "medicamento/" + id, body, { headers: header }).pipe(catchError(this.capturarError));
    return a;
  }

  eliminarMedicamento(id: number): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.delete(this.url + "medicamento/" + id, { headers: header }).pipe(catchError(this.capturarError));
    return a;
  }

  listarTodo(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "inventariomedicamentos/todo", { headers: header }).pipe(catchError(this.capturarError));
    return a;
  }

  vender(_venta: Venta): Observable<any> {
    let body = _venta;
    let headers = this._usuarioService.getToken();
    let a = this._http.post(this.url + "ventamedicamento", body, {
      headers: headers,
    }).pipe(catchError(this.capturarError));
    return a;
  }
  //reportes
  //poca existencia
  pocaExistencia(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "medicamento/pocaexistencia", { headers: header });
    return a;
  }

  // mas vendido por dinero

  masVendidoPorDinero(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "detalleventa/masvendidodinero", { headers: header });
    return a;
  }
  //mas vendido en un rango de fechas
  ///masvendido/:inicio/:fin

  masVendidoRango(fechaInicial, fechaFinal): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `detalleventa/masvendido/${fechaInicial}/${fechaFinal}`, { headers: header });
    return a;
  }
  // mas vendido hoy
  masVendidoHoy(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "detalleventa/masvendidohoy", { headers: header });
    return a;
  }
  // mas vendido hoy Dashboard
  masVendidoHoyDashboard(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "detalleventa/masvendidodashboard", { headers: header });
    return a;
  }

  // mas vendido en 7 dias
  masVendidoSiete(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "detalleventa/masvendidosiete", { headers: header });
    return a;
  }
  //mas vendido 30 dias
  masVendidotreinta(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "detalleventa/masvendidostreinta", { headers: header });
    return a;
  }
  //menos vendido en un rango de fechas  /menosvendido/:inicio/:fin

  menosVendidoRango(fechaInicial, fechaFinal): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `detalleventa/menosvendido/${fechaInicial}/${fechaFinal}`, { headers: header });
    return a;
  }

  actualizarEstadoVencido(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "inventariomedicamentos/actualizarestadovencido", { headers: header });
    return a;
  }

  //cliente mas frecuente

  clienteMasFrecuente(fechaInicial, fechaFinal): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `cliente/clientefrecuente/${fechaInicial}/${fechaFinal}`, { headers: header });
    return a;
  }

  descargarGanancias(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + "justificacionanulacion/exportar", { headers: header, responseType: 'blob' }).pipe(catchError(this.capturarError));
    return a;
  }

}
