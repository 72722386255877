import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { GLOBAL } from "../global";
import { Observable, throwError } from "rxjs";
import { UsuarioService } from "../usuario.service";
import Swal from 'sweetalert2';
import { catchError } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class ListarVentaService {
  private url: string

  constructor(
    public _http: HttpClient,
    private _usuarioService: UsuarioService
  ) {
    this.url = GLOBAL.url;
  }

  capturarError(error: HttpErrorResponse) {
    if (error.status === 422) {
      Swal.fire({
        icon: 'info',
        title: '¡Lo siento!',
        text: 'Su sesión ha expirado',
        showConfirmButton: false,
        allowOutsideClick: false,
        timer: 2000,
        willClose: () => {
          localStorage.clear()
          window.location.replace("/login")
        },
      })
    }
    return throwError(error)
  }

  listarVentasActivas(currentPaginator: number, elementsPerPage: number): Observable<any> {
    let header = this._usuarioService.getToken();
    const body = {
      currentPaginator,
      elementsPerPage
    }
    let a = this._http.post(this.url + `justificacionanulacion/ventasActivas`, body, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  verificarToken(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/verificarToken`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  listarVentasNulas(currentPaginator: number, elementsPerPage: number): Observable<any> {
    let header = this._usuarioService.getToken();
    const body = {
      currentPaginator,
      elementsPerPage
    }
    let a = this._http.post(this.url + `justificacionanulacion/ventasNulas`, body, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  listarVentasConFiltro(currentPaginator: number, elementsPerPage: number, start, end, estado): Observable<any> {
    let header = this._usuarioService.getToken();
    const body = {
      currentPaginator,
      elementsPerPage,
      start,
      end,
      estado
    }
    let a = this._http.post(this.url + `justificacionanulacion/ventasFiltro`, body, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  anularVenta(motivo: string, ventaMedicamento_id: number, detalle: Array<{}>): Observable<any> {
    let header = this._usuarioService.getToken();
    let body = {
      justificacion: {
        motivo
      },
      detalle: detalle,
      ventaMedicamento_id
    }
    let a = this._http.post(this.url + `justificacionanulacion`, body, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  //----Informes prueba-----
  informeVentasHoy(fecha): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/informe/ventasHoy/${fecha}`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  informeVentaRangoFechas(fechaInicial, fechaFinal): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/informe/ventasRangoFechas/${fechaInicial}/${fechaFinal}`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  gananciaRangoFechas(fechaInicial, fechaFinal): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/informe/gananciaRangoFechas/${fechaInicial}/${fechaFinal}`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  gananciaSemana(fechaInicial, fechaFinal): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/informe/gananciaSemana/${fechaInicial}/${fechaFinal}`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  productosProximosVencer(fechaInicial, fechaFinal): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/informe/productosProximosVencer/${fechaInicial}/${fechaFinal}`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  horaMayorVenta(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/informe/horaMayorVenta`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  notiPocaExistencia(fechaInicial, fechaFinal): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/informe/pocaExistencia/${fechaInicial}/${fechaFinal}`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  diasConMasVentas(): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `estanteria/diasConMasVentas`, {
      headers: header,
    }).pipe(catchError(this.capturarError));
    return a;
  }

  getEstanteriasConMasMovimientosHoy(): Observable<any> {    
    let a = this._http.get(this.url + `estanteria/estanteriasconmasmovimientodashboard/1`, {
      headers: this._usuarioService.getToken(),
    }).pipe(catchError(this.capturarError));

    return a;
  }

  ventasRealizasHoyDashboard(): Observable<any> {    
    let a = this._http.get(this.url + `venta/ventashoy`, {
      headers: this._usuarioService.getToken(),
    }).pipe(catchError(this.capturarError));

    return a;
  }
  descargarExcel(fechaInicial, fechaFinal, activo): Observable<any> {
    let header = this._usuarioService.getToken();
    let a = this._http.get(this.url + `justificacionanulacion/excel/ventasRangoFechas/${fechaInicial}/${fechaFinal}/${activo}`, {
      headers: header, responseType: 'blob'
    }).pipe(catchError(this.capturarError));
    return a;
  }

}
